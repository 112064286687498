










import Vue from 'vue'
import { IData, IComputed, IProps } from '@/types/addy_plus/corporations/wallet/AccountList'

export default Vue.extend<IData, unknown, IComputed, IProps>({
  data() {
    return {
      selected: this.value,
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.selected
      },
      set(value) {
        this.selected = value
        this.$emit('input', value)
      },
    },
  },
  props: {
    value: {
      type: String,
    },
    accounts: {
      type: Array,
    },
  },
  watch: {
    value(value) {
      this.selected = value
    },
  },
})
