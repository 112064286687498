

















import Vue from 'vue'
import InputInTwoDecimals from '@components/input/currency-input.vue'
import AccountList from '../components/AccountList.vue'
import { mapState } from 'vuex'
import { addFundsToWallet } from '@api/addy-plus/wallet'
import { IData, IMethods, IComputed } from '@/types/addy_plus/corporations/wallet/AddFunds'
import { AccountInfo } from '@/types/addy_plus/corporations/wallet/AccountList'

export default Vue.extend<IData, IMethods, IComputed>({
  data() {
    return {
      selectedAccount: '',
      amount: 0,
      isAddingFunds: false,
    }
  },
  computed: {
    ...mapState('addPlusEntityFunds', ['connectedAccounts']),
    accountId() {
      return this.$route.params.accountId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
  },
  components: {
    InputInTwoDecimals,
    AccountList,
  },
  watch: {
    connectedAccounts(value) {
      if (value.length > 0) {
        this.selectedAccount = value.find((account: AccountInfo) => account.status === 'verified')?.hashid
      }
    },
  },
  methods: {
    onInput(value) {
      this.amount = value
    },
    onAddFunds() {
      this.isAddingFunds = true
      addFundsToWallet(this.corporationId, this.amount, this.selectedAccount).then((response) => {
        this.isAddingFunds = false
        if (!response.success) return
        this.$router.push(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/wallet`)
      })
    },
  },
})
