/* eslint-disable camelcase */
// @ts-ignore
import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'
import { SuccessResponsePayload } from '../api'

const getImbyRoute = (url: string): string => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * Fetch entity wallet details
 * @param {String} corporationId
 * @return {Promise}
 */
export const fetchWalletDetails = (corporationId: string): Promise<SuccessResponsePayload> => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/details`))
}

/**
 * Fetch connected bank accounts for an entity
 * @param {String} corporationId
 * @return {Promise}
 */
export const fetchConnectedAccounts = (corporationId: string): Promise<SuccessResponsePayload> => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/accounts`))
}

interface PaginatedTransactionsPayload {
  page: number
  limit: number
}

/**
 * get paginated transactions for an entity
 * @param {String} corporationId
 * @param {Object} payload page, limit
 * @return {Promise}
 */
export const getPaginatedTransactions = (corporationId: string, payload: PaginatedTransactionsPayload) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/transactions?page=${payload.page}&limit=${payload.limit}`))
}

interface ConnectAccountInnerPayload {
  account_name: string
  transit_number: string
  routing_number: string
  account_number: string
  is_personal_account: string
  plaid_account_id?: string
  verification_image?: File
}
interface ConnectAccountPayload {
  eft_account: ConnectAccountInnerPayload
}

/**
 * connect account for an entity
 * @param {String} corporationId
 * @param {Object} payload plaid_account_id is present if account is connected via plaid; verification_image is present if account is connected via manual entry
 * @return {Promise}
 */
export const connectAccount = (corporationId: string, payload: ConnectAccountPayload): Promise<SuccessResponsePayload> => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/accounts`), payload)
}

/**
 * get the Plaid link token
 * @param {String} corporationId
 * @return {Promise}
 */
export const getPlaidLinkToken = (corporationId: string): Promise<SuccessResponsePayload> => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/accounts/plaid_token`))
}

interface BankAccountInfoFromPlaidPayload{
  plaid_token: string
  plaid_account_id: string
  plaid_link_session_id: string
}

/**
 * get bank account info from PLAID
 * @param {String} corporationId
 * @param {Object} payload plaid_token, plaid_account_id, plaid_link_session_id
 * @return {Promise}
 */
export const getBankAccountInfoFromPlaid = (corporationId: string, payload: BankAccountInfoFromPlaidPayload): Promise<SuccessResponsePayload> => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/accounts/connect_through_plaid`), { eft_account: payload }, { useMask: true })
}

/**
 * remove connected account
 * @param {String} corporationId
 * @param {String} hashid
 * @return {Promise}
 */
export const removeConnectedAccount = (corporationId: string, hashid: string): Promise<SuccessResponsePayload> => {
  return axios.delete(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/accounts/${hashid}`))
}

/**
 * add funds
 * @param {String} corporationId
 * @param {Number} amount
 * @param {String} id
 * @return {Promise}
 */
export const addFundsToWallet = (corporationId: string, amount: number, id: string): Promise<SuccessResponsePayload> => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/add_funds`), { coins_purchased: amount, eft_account_id: id })
}

/**
 * withdraw funds
 * @param {String} corporationId
 * @param {Number} amount
 * @param {String} id
 * @return {Promise}
 */
export const withdrawFunds = (corporationId: string, amount: number, id: string): Promise<SuccessResponsePayload> => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/withdraw_funds`), { withdrawal_amount: amount, eft_account_id: id }, { noErrorFlash: true })
}

/**
 * cancel coin order
 * @param {String} corporationId
 * @param {String} hashid
 * @return {Promise}
 */
export const cancelEntityCoinOrder = (corporationId: string, hashid: string): Promise<SuccessResponsePayload> => {
  return axios.put(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/cancel_coin_order`), { hashid: hashid })
}

/**
 * cancel withdrawal
 * @param {String} corporationId
 * @param {String} hashid
 * @return {Promise}
 */
export const cancelEntityWithdrawal = (corporationId: string, hashid: string): Promise<SuccessResponsePayload> => {
  return axios.put(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/cancel_withdrawal`), { hashid: hashid })
}

/**
 * get a list of distributions
 * @param {String} corporationId
 * @return {Promise}
 */
export const fetchDistributionList = (corporationId: string): Promise<SuccessResponsePayload> => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/distributions`))
}

interface DistributionPayload {
  rental_income: number
  return_of_capital: number
  capital_gains: number
  business_income: number
}
/**
 * download distribution report
 * @param {String} corporationId
 * @param {Object} payload
 * @return {Promise}
 */
export const fetchDistributionCsv = (corporationId: string, payload: DistributionPayload): Promise<SuccessResponsePayload> => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/distributions/distributions_csv`), { params: payload, responseType: 'blob', downloadFile: true })
}

/**
 * distribute funds
 * @param {String} corporationId
 * @param {Object} payload
 * @return {Promise}
 */
export const distributeFunds = (corporationId: string, payload: DistributionPayload): Promise<SuccessResponsePayload> => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/distributions/distribute`), payload)
}

/**
 * get a list of distribution properties
 * @param {String} corporationId
 * @return {Promise}
 */
export const fetchDistributionProperties = (corporationId: string): Promise<SuccessResponsePayload> => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/distributions/distribution_properties`))
}

/**
 * Member lookup for funds transfer
 * @param {String} corporationId
 * @param {String} searchQuery
 * @return {Promise}
 */
export const fetchInvestedMembers = (corporationId: string, searchQuery: string): Promise<SuccessResponsePayload> => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/member_lookup`), { params: { search_query: searchQuery } })
}

interface TransferFundsPayload {
  hashid: string
  amount: number
}
/**
 * Transfer funds
 * @param {String} corporationId
 * @param {Object} payload
 * @return {Promise}
 */
export const transferFunds = (corporationId: string, payload: TransferFundsPayload): Promise<SuccessResponsePayload> => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/wallets/transfer_funds`), payload)
}
